import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Rose::Form @onSubmit={{fn this.submit @submit this.password}} as |form|>\n\n  <Hds::Form::TextInput::Field\n    @isRequired={{true}}\n    @isOptional={{false}}\n    @value={{this.password}}\n    @type='password'\n    name='password'\n    autocomplete='new-password'\n    disabled={{form.disabled}}\n    {{on 'input' (set-from-event this 'password')}}\n    as |F|\n  >\n    <F.Label>{{t 'form.set_password.label'}}</F.Label>\n    <F.HelperText>{{t 'form.set_password.help'}}</F.HelperText>\n  </Hds::Form::TextInput::Field>\n\n  <form.actions\n    @submitDisabled={{this.cannotSave}}\n    @showCancel={{false}}\n    @submitText={{t 'actions.save'}}\n    @cancelText={{t 'actions.cancel'}}\n  />\n\n</Rose::Form>", {"contents":"<Rose::Form @onSubmit={{fn this.submit @submit this.password}} as |form|>\n\n  <Hds::Form::TextInput::Field\n    @isRequired={{true}}\n    @isOptional={{false}}\n    @value={{this.password}}\n    @type='password'\n    name='password'\n    autocomplete='new-password'\n    disabled={{form.disabled}}\n    {{on 'input' (set-from-event this 'password')}}\n    as |F|\n  >\n    <F.Label>{{t 'form.set_password.label'}}</F.Label>\n    <F.HelperText>{{t 'form.set_password.help'}}</F.HelperText>\n  </Hds::Form::TextInput::Field>\n\n  <form.actions\n    @submitDisabled={{this.cannotSave}}\n    @showCancel={{false}}\n    @submitText={{t 'actions.save'}}\n    @cancelText={{t 'actions.cancel'}}\n  />\n\n</Rose::Form>","moduleName":"admin/components/form/account/password/set-password/index.hbs","parseOptions":{"srcName":"admin/components/form/account/password/set-password/index.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { computed } from '@ember/object';
import { action } from '@ember/object';
import { run } from '@ember/runloop';

export default class FormAccountPasswordSetPasswordIndexComponent extends Component {
  // =properties

  /**
   * New password property
   * @type {string}
   */
  @tracked password;

  /**
   * @type {boolean}
   */
  @computed('password')
  get cannotSave() {
    return !this.password;
  }

  // =methods

  /**
   * Unsets the password field.
   */
  resetPassword() {
    this.password = null;
  }

  // =actions

  /**
   * Submit with password value when it is allowed.
   * Callback with no arguments otherwise.
   * @param {function} fn
   * @param {string} password
   */
  @action
  submit(fn, password) {
    run(() => this.resetPassword());
    run(() => fn(password));
  }
}
