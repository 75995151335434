import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Hds::Badge\n  @icon={{this.icon}}\n  @text={{t (concat 'resources.host-catalog.types.' @model.compositeType)}}\n/>", {"contents":"<Hds::Badge\n  @icon={{this.icon}}\n  @text={{t (concat 'resources.host-catalog.types.' @model.compositeType)}}\n/>","moduleName":"admin/components/host-catalog-type-badge/index.hbs","parseOptions":{"srcName":"admin/components/host-catalog-type-badge/index.hbs"}});
import Component from '@glimmer/component';
const types = ['aws', 'azure'];

export default class HostCatalogTypeComponent extends Component {
  /**
   * Display icons only for plugin compositeTypes.
   * @type {string}
   */
  get icon() {
    return (
      types.includes(this.args.model.compositeType) &&
      `${this.args.model.compositeType}-color`
    );
  }
}
