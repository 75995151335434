import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Rose::Form\n  class='full-width'\n  @cancel={{@cancel}}\n  @disabled={{@model.isSaving}}\n  @onSubmit={{fn @submit this.path}}\n  as |form|\n>\n  <form.radioGroup\n    @name='paths'\n    @selectedValue={{this.path}}\n    @changed={{this.toggleRadio}}\n    as |radioGroup|\n  >\n    <div class='choose-path-cards'>\n      <radioGroup.card\n        @label={{t 'onboarding.quick-start.titles.quick-setup'}}\n        @value='guided'\n        @helperText={{t 'onboarding.quick-start.descriptions.quick-setup'}}\n        @layout='tile'\n      />\n      <radioGroup.card\n        @label={{t 'onboarding.quick-start.titles.manual'}}\n        @value='manual'\n        @helperText={{t 'onboarding.quick-start.descriptions.manual'}}\n        @layout='tile'\n      />\n    </div>\n  </form.radioGroup>\n  <form.actions @submitText={{t 'actions.next'}} @showCancel={{false}} />\n</Rose::Form>", {"contents":"<Rose::Form\n  class='full-width'\n  @cancel={{@cancel}}\n  @disabled={{@model.isSaving}}\n  @onSubmit={{fn @submit this.path}}\n  as |form|\n>\n  <form.radioGroup\n    @name='paths'\n    @selectedValue={{this.path}}\n    @changed={{this.toggleRadio}}\n    as |radioGroup|\n  >\n    <div class='choose-path-cards'>\n      <radioGroup.card\n        @label={{t 'onboarding.quick-start.titles.quick-setup'}}\n        @value='guided'\n        @helperText={{t 'onboarding.quick-start.descriptions.quick-setup'}}\n        @layout='tile'\n      />\n      <radioGroup.card\n        @label={{t 'onboarding.quick-start.titles.manual'}}\n        @value='manual'\n        @helperText={{t 'onboarding.quick-start.descriptions.manual'}}\n        @layout='tile'\n      />\n    </div>\n  </form.radioGroup>\n  <form.actions @submitText={{t 'actions.next'}} @showCancel={{false}} />\n</Rose::Form>","moduleName":"admin/components/form/onboarding/choose-path/index.hbs","parseOptions":{"srcName":"admin/components/form/onboarding/choose-path/index.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class FormOnboardingChoosePathComponent extends Component {
  // =properties

  /**
   * Onboarding path property
   * @type {string}
   */
  @tracked path = 'guided';

  // =actions

  @action
  toggleRadio(path) {
    this.path = path;
  }
}
